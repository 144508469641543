/*eslint-disable import/default */
import axios from 'axios';
import dotenv  from "dotenv"
dotenv.config()

let api_base_url = 'http://localhost:3000';
if(process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV === 'production')  api_base_url = process.env.REACT_APP_API_URL || 'https://portal.thenexus.net';

class GuideApi {
  static getSelectedExhibitors(id) {
    return new Promise((resolve, reject) => {
      axios.get(`${api_base_url}/public_api/buyers_guide/selected_exhibitors/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(err => reject(err));
    });
  }

  static getIndividual(id, synced) {
    return new Promise((resolve, reject) => {
      axios.get(`${api_base_url}/public_api/buyers_guide/individual/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(err => reject(err));
    });
  }

  static getExpo(id) {
    return new Promise((resolve, reject) => {
      axios.get(`${api_base_url}/public_api/buyers_guide/expo/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(err => reject(err));

    });
  }

  static updateIndividual(id, data) {
    return new Promise((resolve, reject) => {
      return axios.post(`${api_base_url}/public_api/buyers_guide/individual/${id}`, data)
      .then(response => {
        resolve(response);
      })
      .catch(err => reject(err));

    });
  }
  static searchIndividual(name_last, badge_number) {
    return new Promise((resolve, reject) => {
      axios.get(`${api_base_url}/public_api/buyers_guide/search_individual?name_last=${name_last}&badge_number=${badge_number}`)
        .then(response => {
          resolve(response);
        })
        .catch(err => reject(err));

    });
  }
  static searchIndividualByEmail(expo_id, email) {
    return new Promise((resolve, reject) => {
      axios.get(`${api_base_url}/public_api/buyers_guide/search_individual?email=${email}&expo_id=${expo_id}`)
        .then(response => {
          resolve(response);
        })
        .catch(err => reject(err));

    });
  }
  static createMarketplaceGuide(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${api_base_url}/public_api/buyers_guide/create_marketplace_guide`, data)
        .then(response => {
          resolve(response);
        })
        .catch(err => reject(err));

    });
  } 
  static createShaderForSenateGuide(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${api_base_url}/public_api/buyers_guide/shader_for_senate`, data)
        .then(response => {
          resolve(response);
        })
        .catch(err => reject(err));

    });
  } 
  static getExpoWithCredentials(id) {
    return new Promise((resolve, reject) => {
      axios.get(`${api_base_url}/api/expo/${id}`, {
        withCredentials: true
      })
        .then(response => {
          resolve(response);
        })
        .catch(err => reject(err));
    });
  }
}

export default GuideApi;
